import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import get from 'lodash/get';
import Carousel from 'react-bootstrap/Carousel';
import Helmet from 'react-helmet';

import NavSection from '../components/nav-section';
import Footer from '../components/footer';
import Layout from '../components/layout';
import BookingForm from '../components/booking-form';

class MainEventsPage extends React.Component {
  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    
    const footer = get(this, 'props.data.allContentfulFooter.edges')
    
    const bookingForm = get(this, 'props.data.allContentfulBookingForm.edges')[0].node;
    return (
      <Layout>
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title="Events" />

        <div className="venue-header mb60">
          <h1 className="mb-0">Experience the Palacio</h1>
          <p className="font-bigcas mt0 mb40">
            Host your event at the Palacio like it were the golden age of
            Manila.
          </p>
        </div>

        <section id="packagesSection" className="mt-4 pb-5 mb-4">
          <Carousel>
            <Carousel.Item>
              <div className="container">
                <div className="venue-whole venue-details venue-slider">
                  <img src="/images/events-weddings1.jpg" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="container">
                <div className="venue-whole venue-details venue-slider">
                  <img src="/images/events-weddings2.png" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="container">
                <div className="venue-whole venue-details venue-slider">
                  <img src="/images/events-weddings3.jpg" />
                </div>
              </div>
            </Carousel.Item>
          </Carousel>

          <div className="container">
            <div className="row justify-content-between align-items-center mt40 venue-whole venue-details">
              <div className="col-lg-12 col-md-12 text-center">
                <h1 class="red mb20 f48">Weddings</h1>
                <p className="font-bigcas mt0 mb40">
                  Combining art and elegance to bring couples celebrating
                  important milestones a memorable occasion.
                </p>
                <div className="row action-btns mb-2 justify-content-center">
                  <a href="#bookingForm">
                    <button className="btn">Book Now</button>
                  </a>
                  <Link
                    to={`/about/faqs-and-guidelines#eventsFaqsGuidelinesSection`}
                  >
                    <button className="btn">View Terms</button>
                  </Link>
                </div>
              </div>
              {/* <div className="col-md-6 inclusions">
                <h4 className="font-gtaM">HOURLY RATES</h4>
                <p className="mt20">
                  • Profesional Photo Shoot - Php 2,500.00
                  <br />• Profesional Video Shoot - Php 3,500.00
                </p>
              </div> */}
            </div>
          </div>
        </section>

        <section id="other-events">
          <div className="container mt60">
            <div className="row">
              <div className="col-md-6 sm-mt90 text-center">
                <div className="venue-whole venue-details venue-slider">
                  <img src="/images/private-event-img2.jpg" />
                </div>
                <h1 class="red mt30 mb30 f48">Private Events</h1>
                <p className="font-bigcas mt0 mb40">
                  We offer private events for corporate, commercial, branding,
                  or media purposes.
                </p>
                <div className="row action-btns mb-2 d-flex justify-content-center">
                  <a href="#bookingForm">
                    <button className="btn mr10 ml10">Book Now</button>
                  </a>
                  <Link
                    to={`/about/faqs-and-guidelines#eventsFaqsGuidelinesSection`}
                  >
                    <button className="btn mr10 ml10">View Terms</button>
                  </Link>
                </div>
              </div>
              <div className="col-md-6 sm-mt90 text-center">
                <div className="venue-whole venue-details venue-slider">
                  <img src="/images/photoshoot-img.jpg" />
                </div>
                <h1 class="red mt30 mb30 f48">Photo and Video Shoots</h1>
                <p className="font-bigcas mt0 mb40">
                  We offer photo and / or video shoots for private, corporate,
                  commercial, branding, or media purposes. Personal shoots are
                  allowed only with rental of venue.
                </p>
                <div className="row action-btns mb-2 d-flex justify-content-center">
                  <a href="#bookingForm">
                    <button className="btn mr10 ml10">Book Now</button>
                  </a>
                  <Link
                    to={`/about/faqs-and-guidelines#eventsFaqsGuidelinesSection`}
                  >
                    <button className="btn mr10 ml10">View Terms</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="bookingformSection" className="mt90">
          <BookingForm bookingForm={bookingForm}/>
        </section>

        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default MainEventsPage;

export const pageQuery = graphql`
  query MainEventsPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulBookingForm {
      edges {
        node {
          header
          firstNameInputPlaceholder
          lastNameInputPlaceholder
          contactNoInputPlaceholder
          emailInputPlaceholder
          bookingTypeInputPlaceholder {
            name
            value
          }
          dateInputPlaceholder
          timeInputPlaceholder
          venueInputPlaceholder {
            name
            value
            selected_value
          }
          messageInputPlaceholder
          newsletterInputPlaceholder
          submitInputPlaceholder
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
